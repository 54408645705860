// client/src/components/OrderModal.js

import React, { useState, useEffect, useContext } from 'react';
import DetailsPanel from './DetailsPanel';
import { notification, Skeleton, Button, Input, Select, Divider, Row, Col, Typography, List } from 'antd';
import { PlusOutlined, MinusOutlined, DeleteOutlined } from '@ant-design/icons';
import api from '../services/api';
import AuthContext from '../context/authContext';
import DetailsPanelContext from '../context/DetailsPanelContext';

const { Option } = Select;
const { Text, Title } = Typography;

const OrderModal = ({ seat }) => {
    const [menuItems, setMenuItems] = useState([]);
    const [menuItem, setMenuItem] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [specialRequests, setSpecialRequests] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [tableNumber, setTableNumber] = useState(seat?.table?.number || '');
    const [chairIndex, setChairIndex] = useState(0);
    const [chairCount, setChairCount] = useState(seat?.table?.capacity || 1);
    const [pendingOrder, setPendingOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const { user } = useContext(AuthContext);
    const { closeDetailsPanel } = useContext(DetailsPanelContext);

    const taxRate = 0.1; // Example tax rate of 10%

    useEffect(() => {
        const fetchData = async () => {
            if (!seat || !seat.table) return;

            try {
                const [menuResponse, orderResponse] = await Promise.all([
                    api.get('/menu-items'),
                    api.get(`/tables/${seat.table._id}/order-status`)
                ]);
                setMenuItems(menuResponse.data);
                if (orderResponse.data && orderResponse.data.status === 'pending') {
                    setPendingOrder(orderResponse.data);
                    setSelectedItems(orderResponse.data.items || []);
                } else {
                    setPendingOrder(null);
                    setSelectedItems([]);
                }
                setLoading(false);
            } catch (error) {
                console.error('Failed to load data:', error);
                setLoading(false);
            }
        };

        if (seat && seat.table) {
            fetchData();
            setChairCount(seat.table.capacity || 1);
            setTableNumber(seat.table.number || '');
        } else {
            setTableNumber('');
        }
    }, [seat]);

    const handleAddItem = () => {
        const selectedItem = menuItems.find(item => item._id === menuItem);
        if (selectedItem) {
            const existingItemIndex = selectedItems.findIndex(
                (item) => item.menu_item_id === selectedItem._id && item.chair_index === chairIndex && item.special_requests === specialRequests
            );

            if (existingItemIndex > -1) {
                const updatedItems = [...selectedItems];
                updatedItems[existingItemIndex].quantity += quantity;
                setSelectedItems(updatedItems);
            } else {
                setSelectedItems([
                    ...selectedItems,
                    {
                        menu_item_id: selectedItem._id,
                        name: selectedItem.name,
                        quantity,
                        price: selectedItem.price,
                        special_requests: specialRequests,
                        chair_index: chairIndex,
                    }
                ]);
            }

            setMenuItem('');
            setQuantity(1);
            setSpecialRequests('');
        }
    };

    const handleQuantityChange = (delta) => {
        setQuantity(prevQuantity => Math.max(1, prevQuantity + delta));
    };

    const adjustItemQuantity = (index, delta) => {
        const updatedItems = [...selectedItems];
        updatedItems[index].quantity = Math.max(1, updatedItems[index].quantity + delta);
        setSelectedItems(updatedItems);
    };

    const handleSpecialRequestChange = (index, newRequest) => {
        const updatedItems = [...selectedItems];
        updatedItems[index].special_requests = newRequest;
        setSelectedItems(updatedItems);
    };

    const handleRemoveItem = (index) => {
        const updatedItems = selectedItems.filter((_, i) => i !== index);
        setSelectedItems(updatedItems);
    };

    const calculateSubtotal = () => selectedItems.reduce((total, item) => total + item.price * item.quantity, 0);
    const calculateTax = () => calculateSubtotal() * taxRate;
    const calculateTotal = () => calculateSubtotal() + calculateTax();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const orderData = {
                table_id: seat?.table?._id,
                table_number: tableNumber,
                items: selectedItems,
                changed_by: user._id,
            };

            if (pendingOrder) {
                await api.put(`/orders/${pendingOrder.order_id}`, orderData);
            } else {
                await api.post('/orders', orderData);
            }
            notification.success({ message: 'Order Submitted!', description: 'The order has been successfully submitted.' });
            closeDetailsPanel();
        } catch (error) {
            console.error('Failed to submit order:', error);
            notification.error({ message: 'Submission Failed', description: 'Could not submit the order.' });
        }
    };

    return (
        <DetailsPanel title="Order Details">
            {loading ? (
                <Skeleton active />
            ) : (
                <form onSubmit={handleSubmit} className="order-form">
                    <Row gutter={16}>
                        <Col xs={24} sm={12}>
                            <label>Table Number</label>
                            <Input value={tableNumber} disabled />
                        </Col>
                        <Col xs={24} sm={12}>
                            <label>Chair Selection</label>
                            <Select
                                value={chairIndex}
                                onChange={setChairIndex}
                                style={{ width: '100%' }}
                                placeholder="Select Chair"
                            >
                                {Array.from({ length: chairCount }, (_, i) => (
                                    <Option key={i} value={i}>Chair {i + 1}</Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>

                    <Row gutter={16} style={{ marginTop: '16px' }}>
                        <Col xs={24} sm={12}>
                            <label>Menu Item</label>
                            <Select
                                value={menuItem}
                                onChange={setMenuItem}
                                style={{ width: '100%' }}
                                placeholder="Select an item"
                            >
                                {menuItems.map(item => (
                                    <Option key={item._id} value={item._id} disabled={!item.available}>
                                        {item.name} - ₹{item.price} {item.available ? '' : '(Not available)'}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={24} sm={12}>
                            <label>Quantity</label>
                            <div className="quantity-control" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <Button icon={<MinusOutlined />} size="small" onClick={() => handleQuantityChange(-1)} />
                                <span className="quantity-value" style={{ minWidth: '24px', textAlign: 'center' }}>{quantity}</span>
                                <Button icon={<PlusOutlined />} size="small" onClick={() => handleQuantityChange(1)} />
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={16} style={{ marginTop: '16px' }}>
                        <Col span={24}>
                            <label>Special Requests</label>
                            <Input.TextArea value={specialRequests} onChange={(e) => setSpecialRequests(e.target.value)} />
                        </Col>
                    </Row>

                    <Button
                        onClick={handleAddItem}
                        icon={<PlusOutlined />}
                        type="primary"
                        block
                        style={{ marginTop: '16px' }}
                        disabled={!menuItem}
                    >
                        Add Item
                    </Button>

                    <Divider />

                    {selectedItems.length > 0 ? (
                        <List
                            dataSource={selectedItems}
                            renderItem={(item, index) => (
                                <List.Item>
                                    <Row style={{ width: '100%' }} justify="space-between" align="middle">
                                        <Col flex="auto">
                                            <Text>{item.name}</Text>
                                            <Text type="secondary" style={{ marginLeft: '8px' }}>(Chair {item.chair_index + 1})</Text>
                                        </Col>
                                        <Col>
                                            <Button
                                                icon={<MinusOutlined />}
                                                size="small"
                                                onClick={() => adjustItemQuantity(index, -1)}
                                            />
                                            <Text style={{ margin: '0 8px' }}>{item.quantity}</Text>
                                            <Button
                                                icon={<PlusOutlined />}
                                                size="small"
                                                onClick={() => adjustItemQuantity(index, 1)}
                                            />
                                        </Col>
                                        <Col>
                                            <Button
                                                icon={<DeleteOutlined />}
                                                type="link"
                                                danger
                                                onClick={() => handleRemoveItem(index)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: '8px' }}>
                                        <Col span={24}>
                                            <Text type="secondary">Special Requests:</Text>
                                            <Input.TextArea
                                                value={item.special_requests}
                                                onChange={(e) => handleSpecialRequestChange(index, e.target.value)}
                                                style={{ marginTop: '4px' }}
                                                placeholder="Add special requests"
                                                rows={1}
                                            />
                                        </Col>
                                    </Row>
                                </List.Item>
                            )}
                        />
                    ) : (
                        <Text>No items added yet.</Text>
                    )}

                    <Divider />

                    <div style={{ padding: '12px', background: '#f0f2f5', borderRadius: '8px' }}>
                        <Title level={5} style={{ marginBottom: '8px', textAlign: 'center' }}>Billing Summary</Title>
                        <Row justify="space-between" style={{ marginBottom: '8px' }}>
                            <Col span={12}><Text strong>Subtotal:</Text></Col>
                            <Col span={12} style={{ textAlign: 'right' }}>₹{calculateSubtotal().toFixed(2)}</Col>
                        </Row>
                        <Row justify="space-between" style={{ marginBottom: '8px' }}>
                            <Col span={12}><Text strong>Tax (10%):</Text></Col>
                            <Col span={12} style={{ textAlign: 'right' }}>₹{calculateTax().toFixed(2)}</Col>
                        </Row>
                        <Divider style={{ margin: '8px 0' }} />
                        <Row justify="space-between" style={{ marginBottom: '8px' }}>
                            <Col span={12}><Text strong>Total Payment:</Text></Col>
                            <Col span={12} style={{ textAlign: 'right' }}>₹{calculateTotal().toFixed(2)}</Col>
                        </Row>
                    </div>

                    <Divider />

                    <div style={{ textAlign: 'right' }}>
                        <Button onClick={closeDetailsPanel} style={{ marginRight: 8 }}>Cancel</Button>
                        <Button htmlType="submit" type="primary">Submit Order</Button>
                    </div>
                </form>
            )}
        </DetailsPanel>
    );
};

export default OrderModal;

