import React from 'react';

const NutritionInfo = ({ nutritionInfo, onChange }) => {
    const handleNutritionInfoChange = (key, value) => {
        onChange({
            ...nutritionInfo,
            [key]: value,
        });
    };

    const handleRemoveNutrient = (key) => {
        const updatedNutritionInfo = { ...nutritionInfo };
        delete updatedNutritionInfo[key];
        onChange(updatedNutritionInfo);
    };

    const handleAddNutrient = () => {
        onChange({
            ...nutritionInfo,
            '': ''
        });
    };

    return (
        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Nutrition Information</label>
            {Object.entries(nutritionInfo).map(([key, value], index) => (
                <div key={index} className="flex mb-2">
                    <input
                        type="text"
                        value={key}
                        onChange={(e) => handleNutritionInfoChange(e.target.value, value)}
                        className="mr-2 block w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        placeholder="Nutrient"
                    />
                    <input
                        type="text"
                        value={value}
                        onChange={(e) => handleNutritionInfoChange(key, e.target.value)}
                        className="block w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        placeholder="Value"
                    />
                    <button
                        type="button"
                        onClick={() => handleRemoveNutrient(key)}
                        className="bg-red-600 text-white px-3 rounded hover:bg-red-700 transition duration-200 ml-2"
                    >
                        Remove
                    </button>
                </div>
            ))}
            <button
                type="button"
                onClick={handleAddNutrient}
                className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition duration-200 mt-2"
            >
                Add Nutrient
            </button>
        </div>
    );
};

export default NutritionInfo;