// client/src/App.js

import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Tables from './pages/Tables';
import MenuItems from './pages/MenuItems';
import Orders from './pages/Orders';
import ChefOrders from './pages/ChefOrders';
import Settings from './pages/Settings';
import AdminSetup from './pages/AdminSetup';
import ProtectedRoute from './components/ProtectedRoute';
import Layout from './components/Layout';
import AuthContext, { AuthProvider } from './context/authContext';
import { SettingsProvider } from './context/SettingsContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DetailsPanelProvider } from './context/DetailsPanelContext';

function App() {
    return (
        <AuthProvider>
            <SettingsProvider>
                <DetailsPanelProvider>
                    <Router>
                        <ToastContainer />
                        <AppRoutes />
                    </Router>
                </DetailsPanelProvider>
            </SettingsProvider>
        </AuthProvider>
    );
}

// client/src/App.js

function AppRoutes() {
    const { adminExists, loading } = useContext(AuthContext);

    if (loading) return <div>Loading...</div>;

    return (
        <Routes>
            {!adminExists ? (
                <Route path="*" element={<AdminSetup />} />
            ) : (
                <>
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="*"
                        element={
                            <Layout>
                                <Routes>
                                    <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
                                    <Route
                                        path="/tables"
                                        element={
                                            <ProtectedRoute
                                                component={(props) => <Tables {...props} />}
                                            />
                                        }
                                    />
                                    <Route path="/menu-items" element={<ProtectedRoute component={MenuItems} />} />
                                    <Route path="/orders" element={<ProtectedRoute component={Orders} />} />
                                    <Route path="/chef-orders" element={<ProtectedRoute component={ChefOrders} />} />
                                    <Route path="/settings" element={<ProtectedRoute component={Settings} />} />
                                    <Route path="*" element={<Navigate to="/dashboard" />} />
                                </Routes>
                            </Layout>
                        }
                    />
                </>
            )}
        </Routes>
    );
}


export default App;
