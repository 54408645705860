// client/src/components/DetailsPanel.js

import React from 'react';
import PropTypes from 'prop-types';

const DetailsPanel = ({ title, children }) => {
    return (
        <div
            style={{
                width: '400px',
                borderLeft: '1px solid #f0f0f0',
                padding: '24px',
                backgroundColor: '#fff',
                height: '100vh',
                overflowY: 'auto',
                position: 'fixed',
                right: 0,
                top: 0,
                zIndex: 1, // Ensures it's above content but not covering any modal or drawer
            }}
        >
            <h2 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '16px' }}>
                {title || 'Details'}
            </h2>
            <div>{children}</div>
        </div>
    );
};

DetailsPanel.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default DetailsPanel;
