// client/src/components/Inventory.js

import React, { useState, useEffect, useContext } from 'react';
import api from '../services/api';
import SettingsContext from '../context/SettingsContext';
import { toast } from 'react-toastify';
import Table from './Table';
import Loader from './Loader';
import Modal from './Modal'; // Import Modal component

const Inventory = () => {
    const [inventoryItems, setInventoryItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const { currencySymbol } = useContext(SettingsContext);

    const [ingredientId, setIngredientId] = useState('');
    const [totalQuantity, setTotalQuantity] = useState('');
    const [averagePricePerUnit, setAveragePricePerUnit] = useState('');
    const [reorderPoint, setReorderPoint] = useState('');

    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [editingItemId, setEditingItemId] = useState(null);
    const [editTotalQuantity, setEditTotalQuantity] = useState('');
    const [editAveragePricePerUnit, setEditAveragePricePerUnit] = useState('');
    const [editReorderPoint, setEditReorderPoint] = useState('');

    useEffect(() => {
        fetchInventory();
    }, []);

    const fetchInventory = async () => {
        setLoading(true);
        try {
            const response = await api.get('/inventory');
            setInventoryItems(response.data);
        } catch (error) {
            console.error('Error fetching inventory:', error);
            toast.error('Failed to load inventory data');
        } finally {
            setLoading(false);
        }
    };

    const refreshInventory = async () => {
        setLoading(true);
        try {
            await api.post('/inventory/update-manual');
            toast.success('Inventory updated successfully!');
            await fetchInventory();
        } catch (error) {
            console.error('Error updating inventory manually:', error);
            toast.error('Failed to update inventory');
        } finally {
            setLoading(false);
        }
    };

    const addInventoryItem = async (e) => {
        e.preventDefault();
        try {
            const newInventoryItem = {
                ingredient_id: ingredientId,
                total_quantity: totalQuantity,
                average_price_per_unit: averagePricePerUnit,
                reorder_point: reorderPoint
            };

            await api.post('/inventory/add', newInventoryItem);
            toast.success('Inventory item added successfully!');
            await fetchInventory();
            setIsAddModalOpen(false); // Close modal after successful submission
        } catch (error) {
            console.error('Error adding inventory item:', error);
            toast.error('Failed to add inventory item');
        }
    };

    // Function to open the add inventory modal
    const handleOpenAddModal = () => {
        setIsAddModalOpen(true);
    };

    // Function to close the add inventory modal
    const handleCloseAddModal = () => {
        setIsAddModalOpen(false);
    };

    const handleEdit = (item) => {
        setEditingItemId(item._id);
        setEditTotalQuantity(item.total_quantity);
        setEditAveragePricePerUnit(item.average_price_per_unit);
        setEditReorderPoint(item.reorder_point);
    };

    const submitEdit = async (e) => {
        e.preventDefault();
        try {
            const updatedItem = {
                total_quantity: editTotalQuantity,
                average_price_per_unit: editAveragePricePerUnit,
                reorder_point: editReorderPoint,
            };

            await api.put(`/inventory/${editingItemId}/edit`, updatedItem);
            toast.success('Inventory item updated successfully!');
            setEditingItemId(null);
            await fetchInventory();
        } catch (error) {
            console.error('Error editing inventory item:', error);
            toast.error('Failed to edit inventory item');
        }
    };

    const handleDelete = async (id) => {
        try {
            await api.delete(`/inventory/${id}/delete`);
            toast.success('Inventory item deleted successfully!');
            await fetchInventory();
        } catch (error) {
            console.error('Error deleting inventory item:', error);
            toast.error('Failed to delete inventory item');
        }
    };

    return (
        <div>
            <h2 className="text-xl font-semibold mb-4">Inventory Management</h2>

            <button
                className="bg-green-500 text-white px-4 py-2 rounded mb-4"
                onClick={handleOpenAddModal}
            >
                Add Inventory
            </button>

            <Modal
                isOpen={isAddModalOpen}
                onClose={handleCloseAddModal}
                title="Add New Inventory Item"
            >
                <form onSubmit={addInventoryItem}>
                    <div>
                        <label>Ingredient ID:</label>
                        <input
                            type="text"
                            value={ingredientId}
                            onChange={(e) => setIngredientId(e.target.value)}
                            className="border p-2 mb-2 w-full"
                            required
                        />
                    </div>
                    <div>
                        <label>Total Quantity:</label>
                        <input
                            type="number"
                            value={totalQuantity}
                            onChange={(e) => setTotalQuantity(e.target.value)}
                            className="border p-2 mb-2 w-full"
                            required
                        />
                    </div>
                    <div>
                        <label>Average Price Per Unit:</label>
                        <input
                            type="number"
                            value={averagePricePerUnit}
                            onChange={(e) => setAveragePricePerUnit(e.target.value)}
                            className="border p-2 mb-2 w-full"
                            required
                        />
                    </div>
                    <div>
                        <label>Reorder Point:</label>
                        <input
                            type="number"
                            value={reorderPoint}
                            onChange={(e) => setReorderPoint(e.target.value)}
                            className="border p-2 mb-2 w-full"
                            required
                        />
                    </div>
                    <div className="flex justify-end">
                        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded mr-2">
                            Add Item
                        </button>
                        <button
                            type="button"
                            className="bg-gray-500 text-white px-4 py-2 rounded"
                            onClick={handleCloseAddModal}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </Modal>

            <button
                className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
                onClick={refreshInventory}
                disabled={loading}
            >
                {loading ? 'Updating...' : 'Update Inventory'}
            </button>

            {editingItemId && (
                <form onSubmit={submitEdit} className="mb-4">
                    <div>
                        <label>Total Quantity:</label>
                        <input
                            type="number"
                            value={editTotalQuantity}
                            onChange={(e) => setEditTotalQuantity(e.target.value)}
                            className="border p-2 mb-2"
                            required
                        />
                    </div>
                    <div>
                        <label>Average Price Per Unit:</label>
                        <input
                            type="number"
                            value={editAveragePricePerUnit}
                            onChange={(e) => setEditAveragePricePerUnit(e.target.value)}
                            className="border p-2 mb-2"
                            required
                        />
                    </div>
                    <div>
                        <label>Reorder Point:</label>
                        <input
                            type="number"
                            value={editReorderPoint}
                            onChange={(e) => setEditReorderPoint(e.target.value)}
                            className="border p-2 mb-2"
                            required
                        />
                    </div>
                    <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                        Save Changes
                    </button>
                    <button
                        type="button"
                        className="bg-gray-500 text-white px-4 py-2 ml-2 rounded"
                        onClick={() => setEditingItemId(null)}
                    >
                        Cancel
                    </button>
                </form>
            )}

            {inventoryItems.length > 0 ? (
                <Table
                    columns={['Ingredient', 'Available Quantity', 'Average Price per Unit', 'Reorder Point', 'Stock Status', 'Actions']}
                    data={inventoryItems.map(item => ({
                        ingredient: item.ingredient_id?.name?.en || item.ingredient_id?.name || 'N/A',
                        totalQuantity: item.total_quantity || 'N/A',
                        averagePricePerUnit: `${currencySymbol}${item.average_price_per_unit.toFixed(2)}`,
                        reorderPoint: item.reorder_point || 'N/A',
                        stockStatus: item.current_stock_status || 'N/A',
                        actions: (
                            <>
                                <button onClick={() => handleEdit(item)} className="bg-yellow-500 text-white px-2 py-1 rounded mr-2">
                                    Edit
                                </button>
                                <button onClick={() => handleDelete(item._id)} className="bg-red-500 text-white px-2 py-1 rounded">
                                    Delete
                                </button>
                            </>
                        ),
                    }))}
                />
            ) : (
                <p>No inventory data available.</p>
            )}
        </div>
    );
};

export default Inventory;

