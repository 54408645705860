// client/src/components/BulkUpload.js

import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import api from '../services/api'; // Use the api instance with the interceptor
import AuthContext from '../context/authContext'; // Import AuthContext

const BulkUpload = ({ uploadEndpoint, uploadLabel = 'Upload File', accept = '.csv, .xml, .json, .xlsx' }) => {
    const [file, setFile] = useState(null);
    const { user } = useContext(AuthContext); // Get the current user from AuthContext

    const handleFileChange = (e) => {
        const uploadedFile = e.target.files[0];
        setFile(uploadedFile);
    };

    const handleFileUpload = async () => {
        if (!file) {
            toast.error('No file selected');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('created_by', user._id); // Include the user ID
        formData.append('modified_by', user._id); // Include the user ID

        try {
            await api.post(uploadEndpoint, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success('File uploaded successfully');
        } catch (error) {
            toast.error('Error uploading the file: ' + error.message);
        }
    };

    return (
        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
                {uploadLabel}
            </label>
            <input
                type="file"
                accept={accept}
                onChange={handleFileChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
            <button
                onClick={handleFileUpload}
                className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition duration-200 mt-2"
            >
                Upload
            </button>
        </div>
    );
};

export default BulkUpload;




