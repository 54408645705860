// client/src/components/Loader.js

import React from 'react';

const Loader = ({ height = '100px', width = '100px' }) => {
    return (
        <div className="flex items-center justify-center" style={{ minHeight: height }}>
            <div className="w-16 h-16 bg-blue-500 rounded-full animate-ping" style={{ width, height }}></div>
        </div>
    );
};

export default Loader;
