// client/src/pages/Orders.js

import React, { useState, useEffect, useContext } from 'react';
import api from '../services/api';
import Table from '../components/Table';
import Modal from '../components/Modal';
import { formatDistanceToNow, parseISO, isValid, format } from 'date-fns';
import SettingsContext from '../context/SettingsContext';

const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { currencySymbol } = useContext(SettingsContext);

    useEffect(() => {
        fetchOrders();
    }, []);

    const fetchOrders = async () => {
        try {
            const response = await api.get('/orders');
            setOrders(response.data || []);
        } catch (error) {
            console.error("Error fetching orders: ", error);
            setOrders([]);
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = parseISO(dateString);
        return isValid(date) ? format(date, 'PPpp') : 'Invalid Date';
    };

    const formatCurrency = (amount) => {
        if (amount === null || amount === undefined) return 'N/A';
        const num = parseFloat(amount);
        return isNaN(num) ? 'Invalid Amount' : `${currencySymbol}${num.toFixed(2)}`;
    };

    const handleRowClick = async (order) => {
        if (!order._id) {
            console.error("Order ID is undefined");
            return;
        }
        setIsLoading(true);
        try {
            const response = await api.get(`/orders/${order._id}`);
            setSelectedOrder(response.data);
            setIsModalOpen(true);
        } catch (error) {
            console.error("Error fetching order details: ", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedOrder(null);
    };

    const filterOrdersByDateRange = (order) => {
        const orderDate = order.createdAt ? parseISO(order.createdAt) : null;
        const start = startDate ? parseISO(startDate) : null;
        const end = endDate ? parseISO(endDate) : null;

        if (!isValid(orderDate)) return false;

        const isSameDay = (date1, date2) => {
            return date1.getFullYear() === date2.getFullYear() &&
                date1.getMonth() === date2.getMonth() &&
                date1.getDate() === date2.getDate();
        };

        if (start && !end) {
            return orderDate >= start || isSameDay(orderDate, start);
        }
        if (end && !start) {
            return orderDate <= end || isSameDay(orderDate, end);
        }
        if (start && end) {
            return (orderDate >= start && orderDate <= end) ||
                isSameDay(orderDate, start) ||
                isSameDay(orderDate, end);
        }
        return true;
    };

    const columns = ['ID', 'Order Date', 'Order Time', 'Order ID', 'Table Number', 'Total Amount', 'Status', 'Time Since Order'];

    return (
        <div className="container mx-auto mt-10">
            <h1 className="text-2xl font-bold mb-6">Manage Orders</h1>

            <div className="mb-4 flex space-x-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Start Date</label>
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="mt-1 block w-full border px-2 py-1 text-sm rounded-md"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">End Date</label>
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="mt-1 block w-full border px-2 py-1 text-sm rounded-md"
                    />
                </div>
            </div>

            <Table
                columns={columns}
                data={(orders || [])
                    .filter(filterOrdersByDateRange)
                    .map(order => {
                        const orderDate = order.createdAt ? parseISO(order.createdAt) : null;
                        return {
                            _id: order._id, // Include the _id in the row data
                            order_date: isValid(orderDate) ? orderDate.toLocaleDateString() : 'Invalid Date',
                            order_time: isValid(orderDate) ? orderDate.toLocaleTimeString() : 'Invalid Time',
                            order_id: order.order_id || 'N/A',
                            table_number: order.table_id?.number || 'N/A',
                            total_amount: formatCurrency(order.total_amount),
                            status: order.status || 'N/A',
                            time_since_order: isValid(orderDate) ? formatDistanceToNow(orderDate, { addSuffix: true }) : 'N/A',
                        };
                    })}
                rowClickable={true}
                onRowClick={handleRowClick}
            />

            <Modal isOpen={isModalOpen} onClose={handleCloseModal} title="Order Details">
                {isLoading ? (
                    <p>Loading order details...</p>
                ) : selectedOrder && (
                    <div className="space-y-4">
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <p><strong>Order ID:</strong> {selectedOrder.order_id || 'N/A'}</p>
                                <p><strong>Created At:</strong> {formatDate(selectedOrder.createdAt)}</p>
                                <p><strong>Updated At:</strong> {formatDate(selectedOrder.updatedAt)}</p>
                                <p><strong>Status:</strong> {selectedOrder.status || 'N/A'}</p>
                            </div>
                            <div>
                                <p><strong>Table Number:</strong> {selectedOrder.table_id?.number || 'N/A'}</p>
                                <p><strong>Table Capacity:</strong> {selectedOrder.table_id?.capacity || 'N/A'}</p>
                                <p><strong>Table Status:</strong> {selectedOrder.table_id?.status || 'N/A'}</p>
                                <p><strong>Table Shape:</strong> {selectedOrder.table_id?.shape || 'N/A'}</p>
                            </div>
                        </div>

                        <div>
                            <h3 className="text-lg font-semibold mt-4 mb-2">Order Items</h3>
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                {selectedOrder.items && selectedOrder.items.map((item, index) => (
                                    <tr key={index}>
                                        <td className="px-6 py-4 whitespace-nowrap">{item.name || item.menu_item_id?.name || 'N/A'}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{item.quantity || 'N/A'}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{formatCurrency(item.price)}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{item.preparation_phase || 'N/A'}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <h3 className="text-lg font-semibold mt-4 mb-2">Item Status Timeline</h3>
                            {selectedOrder.items && selectedOrder.items.map((item, itemIndex) => (
                                <div key={itemIndex} className="mb-4">
                                    <h4 className="font-medium">{item.name || item.menu_item_id?.name || 'N/A'}</h4>
                                    <ul className="list-disc list-inside">
                                        {item.status_timestamps && item.status_timestamps.map((status, statusIndex) => (
                                            <li key={statusIndex}>
                                                {status.status || 'N/A'}: {formatDate(status.timestamp)}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>

                        <p className="text-xl font-bold mt-4">
                            <strong>Total Amount:</strong> {formatCurrency(selectedOrder.total_amount)}
                        </p>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default Orders;