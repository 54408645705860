// client/src/components/DeviceRegistry.js

const DeviceRegistry = (() => {
    let devices = JSON.parse(localStorage.getItem('devices')) || [];

    const addDevice = (device) => {
        devices.push(device);
        localStorage.setItem('devices', JSON.stringify(devices));
    };

    const removeDevice = (deviceId) => {
        devices = devices.filter(device => device.deviceId !== deviceId);
        localStorage.setItem('devices', JSON.stringify(devices));
    };

    const getDeviceById = (deviceId) => {
        return devices.find(device => device.deviceId === deviceId);
    };

    const getDevicesByType = (type) => {
        return devices.filter(device => device.type === type);
    };

    const getAllDevices = () => devices;

    return {
        addDevice,
        removeDevice,
        getDeviceById,
        getDevicesByType,
        getAllDevices,
    };
})();

export default DeviceRegistry;
