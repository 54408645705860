// client/src/components/RoleManagement.js
import React, { useState, useEffect } from 'react';
import api from '../services/api';
import { toast } from 'react-toastify';

const RoleManagement = () => {
    const [roles, setRoles] = useState([]);
    const [roleName, setRoleName] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await api.get('/roles');
                setRoles(response.data);
            } catch (error) {
                toast.error('Failed to load roles');
            }
        };

        fetchRoles();
    }, []);

    const handleAddRole = async () => {
        try {
            const response = await api.post('/roles', { name: roleName, description });
            setRoles([...roles, response.data]);
            toast.success('Role added successfully');
        } catch (error) {
            toast.error('Failed to add role');
        }
    };

    return (
        <div>
            <h2 className="text-xl font-bold mb-4">Role Management</h2>
            <input
                type="text"
                placeholder="Role Name"
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
                className="input"
            />
            <input
                type="text"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="input"
            />
            <button onClick={handleAddRole} className="btn btn-primary mt-4">
                Add Role
            </button>

            <h3 className="mt-6">Existing Roles:</h3>
            <ul>
                {roles.map((role) => (
                    <li key={role._id}>{role.name} - {role.description}</li>
                ))}
            </ul>
        </div>
    );
};

export default RoleManagement;
