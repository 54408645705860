// client/src/components/RestaurantSettings.js

import React, { useState } from 'react';
import Categories from './Categories';
import MenuItems from './MenuItems';
import Ingredients from './Ingredients';
import Stock from './Stock';
import Inventory from './Inventory';  // Import the new Inventory component
import GeneralSettings from './GeneralSettings';  // Import the new GeneralSettings component

const RestaurantSettings = () => {
    const [activeTab, setActiveTab] = useState('general');

    const renderTabContent = () => {
        switch (activeTab) {
            case 'general':
                return <GeneralSettings />;
            case 'categories':
                return <Categories />;
            case 'menuItems':
                return <MenuItems />;
            case 'ingredients':
                return <Ingredients />;
            case 'stock':
                return <Stock />;
            case 'inventory':
                return <Inventory />;
            default:
                return null;
        }
    };

    return (
        <div className="restaurant-settings-container">
            <div className="tabs mb-4">
                <button
                    className={`tab ${activeTab === 'general' ? 'active' : ''}`}
                    onClick={() => setActiveTab('general')}
                >
                    General Settings
                </button>
                <button
                    className={`tab ${activeTab === 'categories' ? 'active' : ''}`}
                    onClick={() => setActiveTab('categories')}
                >
                    Categories
                </button>
                <button
                    className={`tab ${activeTab === 'menuItems' ? 'active' : ''}`}
                    onClick={() => setActiveTab('menuItems')}
                >
                    Menu Items
                </button>
                <button
                    className={`tab ${activeTab === 'ingredients' ? 'active' : ''}`}
                    onClick={() => setActiveTab('ingredients')}
                >
                    Ingredients
                </button>
                <button
                    className={`tab ${activeTab === 'stock' ? 'active' : ''}`}
                    onClick={() => setActiveTab('stock')}
                >
                    Stock
                </button>
                <button
                    className={`tab ${activeTab === 'inventory' ? 'active' : ''}`}
                    onClick={() => setActiveTab('inventory')}
                >
                    Inventory
                </button>
            </div>

            <div className="tab-content">
                {renderTabContent()}
            </div>
        </div>
    );
};

export default RestaurantSettings;
