// client/src/components/ConnectedDevices.js

import React, { useState, useEffect } from 'react';
import Modal from '../components/Modal';
import DevicePairing from '../components/DevicePairing';

const ConnectedDevices = () => {
    const [deviceId, setDeviceId] = useState(() => localStorage.getItem('pairedDeviceId') || '');
    const [isPaired, setIsPaired] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);  // Modal state

    // Example list of available devices with domain and name
    const availableDevices = [
        { domain: 'esp32-scale.local', name: 'ESP32 Scale 1' },
        { domain: 'esp32-scale-2.local', name: 'ESP32 Scale 2' },
        { domain: 'other-device.local', name: 'Other Device' }
    ];

    useEffect(() => {
        if (deviceId) {
            setIsPaired(true);
        }
    }, [deviceId]);

    const handlePairDevice = () => {
        if (!deviceId) {
            alert('Please enter a valid device ID');
            return;
        }
        localStorage.setItem('pairedDeviceId', deviceId);
        setIsPaired(true);
        alert('Device paired successfully!');
    };

    const handleUnpairDevice = () => {
        localStorage.removeItem('pairedDeviceId');
        setDeviceId('');
        setIsPaired(false);
        alert('Device unpaired successfully!');
    };

    // Handle device pairing from the modal
    const handleModalPairDevice = (id) => {
        localStorage.setItem('pairedDeviceId', id);
        setDeviceId(id);
        setIsPaired(true);
        setIsModalOpen(false);  // Close the modal after pairing
        alert(`Device ${id} paired successfully!`);
    };

    return (
        <div>
            <h2 className="text-lg font-semibold">Pair with Weighing Scale</h2>

            <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">Current Paired Device ID</label>
                <input
                    type="text"
                    value={deviceId}
                    readOnly
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
            </div>

            <div className="mt-4">
                {!isPaired ? (
                    <button
                        onClick={handlePairDevice}
                        className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-200"
                    >
                        Pair Device
                    </button>
                ) : (
                    <button
                        onClick={handleUnpairDevice}
                        className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-200"
                    >
                        Unpair Device
                    </button>
                )}
            </div>

            <div className="mt-6">
                <button
                    onClick={() => setIsModalOpen(true)}  // Open the modal
                    className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 transition duration-200"
                >
                    Pair New Device
                </button>
            </div>

            {/* Modal for scanning and pairing devices */}
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} title="Scan and Pair New Device">
                <DevicePairing availableDevices={availableDevices} onPair={handleModalPairDevice} />  {/* Pass available devices to DevicePairing */}
            </Modal>
        </div>
    );
};

export default ConnectedDevices;

