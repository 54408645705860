// client/src/components/StorageLocations.js

import React, { useState, useEffect } from 'react';
import api from '../services/api';
import { toast } from 'react-toastify';
import Table from './Table';
import Modal from './Modal';
import StorageLocationForm from './StorageLocationForm';
import Loader from './Loader';

const StorageLocations = () => {
    const [storageLocations, setStorageLocations] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchStorageLocations = async () => {
            try {
                const response = await api.get('/storage-locations');
                setStorageLocations(response.data);
            } catch (error) {
                toast.error('Failed to load storage locations.');
            } finally {
                setLoading(false);
            }
        };

        fetchStorageLocations();
    }, []);

    const handleOpenModal = (location) => {
        setCurrentLocation(location);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setCurrentLocation(null);
        setIsModalOpen(false);
    };

    const refreshData = async () => {
        try {
            const response = await api.get('/storage-locations');
            setStorageLocations(response.data);
        } catch (error) {
            toast.error('Failed to refresh data.');
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this storage location?')) {
            try {
                await api.delete(`/storage-locations/${id}`);
                toast.success('Storage location deleted successfully.');
                refreshData();
            } catch (error) {
                toast.error('Failed to delete storage location.');
            }
        }
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div>
            <button
                className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition duration-200 mb-4"
                onClick={() => handleOpenModal(null)}
            >
                Add Storage Location
            </button>
            <Table
                columns={['Name', 'Description', 'Actions']}
                data={storageLocations.map(location => ({
                    name: location.name,
                    description: location.description,
                    actions: (
                        <div className="flex space-x-2">
                            <button
                                onClick={() => handleOpenModal(location)}
                                className="bg-yellow-500 text-white px-2 py-1 rounded"
                            >
                                Edit
                            </button>
                            <button
                                onClick={() => handleDelete(location._id)}
                                className="bg-red-600 text-white px-2 py-1 rounded"
                            >
                                Delete
                            </button>
                        </div>
                    ),
                }))}
            />
            <Modal isOpen={isModalOpen} onClose={closeModal} title={currentLocation ? "Edit Storage Location" : "Add Storage Location"}>
                <StorageLocationForm storageLocation={currentLocation} onSave={refreshData} onCancel={closeModal} />
            </Modal>
        </div>
    );
};

export default StorageLocations;
