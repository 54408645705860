// client/src/context/SettingsContext.js

import React, { createContext, useState, useEffect } from 'react';
import api from '../services/api'; // Assuming you have an api service for requests

const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
    const [appName, setAppName] = useState('MyRestaurant');
    const [primaryColor, setPrimaryColor] = useState('#3B82F6'); // Tailwind Blue
    const [secondaryColor, setSecondaryColor] = useState('#6B7280'); // Tailwind Gray
    const [currency, setCurrency] = useState('INR');
    const [currencySymbol, setCurrencySymbol] = useState('₹');
    const [language, setLanguage] = useState('en');
    const [allowOverrideOutOfStock, setAllowOverrideOutOfStock] = useState(false); // Add this to manage override settings

    useEffect(() => {
        const loadSettings = async () => {
            try {
                const response = await api.get('/settings');
                if (response.data) {
                    setAppName(response.data.appName || 'MyRestaurant');
                    setPrimaryColor(response.data.primaryColor || '#3B82F6');
                    setSecondaryColor(response.data.secondaryColor || '#6B7280');
                    setCurrency(response.data.currency || 'INR');
                    setCurrencySymbol(response.data.currencySymbol || '₹');
                    setLanguage(response.data.language || 'en');
                    setAllowOverrideOutOfStock(response.data.allowOverrideOutOfStock || false); // Load the override setting
                }
            } catch (error) {
                console.error('Failed to load settings:', error);
            }
        };

        loadSettings();
    }, []);

    const updateAppName = (name) => setAppName(name);
    const updatePrimaryColor = (color) => setPrimaryColor(color);
    const updateSecondaryColor = (color) => setSecondaryColor(color);
    const updateCurrency = (newCurrency) => setCurrency(newCurrency);
    const updateLanguage = (newLanguage) => setLanguage(newLanguage);
    const updateAllowOverrideOutOfStock = (newValue) => setAllowOverrideOutOfStock(newValue);

    return (
        <SettingsContext.Provider
            value={{
                appName,
                primaryColor,
                secondaryColor,
                currency,
                currencySymbol,
                language,
                allowOverrideOutOfStock, // Provide the override value
                updateAppName,
                updatePrimaryColor,
                updateSecondaryColor,
                updateCurrency,
                updateLanguage,
                updateAllowOverrideOutOfStock, // Provide the update function
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};

export default SettingsContext;
