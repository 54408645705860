// client/src/components/GeneralSettings.js

import React, { useState, useEffect } from 'react';
import api from '../services/api';
import { toast } from 'react-toastify';

const GeneralSettings = () => {
    const [allowOverrideOutOfStock, setAllowOverrideOutOfStock] = useState(false);
    const [settings, setSettings] = useState({}); // Store all settings

    // Fetch the settings when the component mounts
    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await api.get('/settings');
                setSettings(response.data); // Save all settings
                setAllowOverrideOutOfStock(response.data.allowOverrideOutOfStock);
            } catch (error) {
                toast.error('Failed to load settings');
            }
        };
        fetchSettings();
    }, []);

    // Handle toggling the override setting
    const handleToggleOverride = async (e) => {
        const newValue = e.target.checked;
        setAllowOverrideOutOfStock(newValue);

        // Create a new settings object with the updated override setting
        const updatedSettings = { ...settings, allowOverrideOutOfStock: newValue };

        try {
            await api.put('/settings', updatedSettings); // Send all settings to the API
            toast.success('Settings updated successfully');
        } catch (error) {
            toast.error('Failed to update settings');
        }
    };

    return (
        <div className="general-settings">
            <h2 className="text-2xl font-bold mb-4">General Settings</h2>
            <div className="mb-4">
                <label htmlFor="override-stock" className="inline-flex items-center">
                    <input
                        type="checkbox"
                        id="override-stock"
                        className="mr-2"
                        checked={allowOverrideOutOfStock}
                        onChange={handleToggleOverride}
                    />
                    Allow chefs to override out-of-stock items
                </label>
            </div>
        </div>
    );
};

export default GeneralSettings;

