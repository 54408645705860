// client/src/pages/MenuItems.js

import React, { useState, useEffect, useContext } from 'react';
import api from '../services/api';
import Table from '../components/Table';
import Modal from '../components/Modal';
import { toast } from 'react-toastify';
import SettingsContext from '../context/SettingsContext';

const MenuItems = () => {
    const [menuItems, setMenuItems] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { currencySymbol } = useContext(SettingsContext);

    useEffect(() => {
        fetchMenuItems();
    }, []);

    const fetchMenuItems = async () => {
        try {
            const response = await api.get('/menu-items');
            setMenuItems(response.data);
        } catch (error) {
            console.error('Error fetching menu items:', error);
            toast.error('Failed to load menu items: ' + (error.response?.data?.message || error.message));
        }
    };

    const handleRowClick = async (item) => {
        setIsLoading(true);
        setError(null);
        try {
            if (!item.id) {
                throw new Error('No valid identifier found for the menu item');
            }
            const response = await api.get(`/menu-items/${item.id}`);
            setCurrentItem(response.data);
            setIsModalOpen(true);
        } catch (error) {
            console.error('Error fetching menu item details:', error);
            setError('Failed to load menu item details. Please try again.');
            toast.error('Failed to load menu item details: ' + (error.message || 'Unknown error'));
        } finally {
            setIsLoading(false);
        }
    };

    const columns = ['Name', 'Description', 'Price', 'Availability', 'Category', 'Preparation Time'];

    const tableData = menuItems.map(item => ({
        id: item._id,
        name: item.name,
        description: item.description,
        price: `${currencySymbol}${(item.price || 0).toFixed(2)}`,
        availability: item.available ? 'Available' : 'Not Available',
        category: item.category_id?.name,
        preparationTime: item.preparation_time ? `${item.preparation_time} mins` : 'N/A',
    }));

    const renderIngredients = (ingredients) => {
        if (!ingredients || ingredients.length === 0) {
            return <li>No ingredients information available</li>;
        }
        return ingredients.map((ingredient, index) => {
            const ingredientName = ingredient.ingredient_id?.name?.name?.en || 'Unknown Ingredient';
            return (
                <li key={index}>
                    {ingredientName} - {ingredient.quantity} {ingredient.unit.abbreviation}
                    {ingredient.optional ? ' (Optional)' : ''}
                </li>
            );
        });
    };

    const renderNutritionInfo = (nutritionInfo) => {
        if (!nutritionInfo || Object.keys(nutritionInfo).length === 0) {
            return <li>No nutrition information available</li>;
        }
        return Object.entries(nutritionInfo).map(([key, value], index) => (
            <li key={index}>{key}: {value.toString()}</li>
        ));
    };

    return (
        <div className="container mx-auto mt-10">
            <h1 className="text-2xl font-bold mb-6">Menu Items</h1>
            {menuItems.length > 0 ? (
                <Table
                    columns={columns}
                    data={tableData}
                    rowClickable={true}
                    onRowClick={handleRowClick}
                />
            ) : (
                <p>No menu items available.</p>
            )}
            <Modal
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                    setCurrentItem(null);
                    setError(null);
                }}
                title="Menu Item Details"
            >
                {isLoading ? (
                    <p>Loading menu item details...</p>
                ) : error ? (
                    <div className="text-red-500">
                        <p>{error}</p>
                        <p>Please try again or contact support if the problem persists.</p>
                    </div>
                ) : currentItem ? (
                    <div className="space-y-4">
                        <div>
                            <h2 className="text-lg font-bold">Name</h2>
                            <p>{currentItem.name}</p>
                        </div>
                        <div>
                            <h2 className="text-lg font-bold">Description</h2>
                            <p>{currentItem.description}</p>
                        </div>
                        <div>
                            <h2 className="text-lg font-bold">Price</h2>
                            <p>{currencySymbol}{(currentItem.price || 0).toFixed(2)}</p>
                        </div>
                        <div>
                            <h2 className="text-lg font-bold">Category</h2>
                            <p>{currentItem.category_id?.name}</p>
                        </div>
                        <div>
                            <h2 className="text-lg font-bold">Ingredients</h2>
                            <ul className="list-disc list-inside">
                                {renderIngredients(currentItem.ingredients)}
                            </ul>
                        </div>
                        <div>
                            <h2 className="text-lg font-bold">Nutrition Info</h2>
                            <ul className="list-disc list-inside">
                                {renderNutritionInfo(currentItem.nutrition_info)}
                            </ul>
                        </div>
                        <div>
                            <h2 className="text-lg font-bold">Dietary Category</h2>
                            <p>{currentItem.dietary_category || 'N/A'}</p>
                        </div>
                        <div>
                            <h2 className="text-lg font-bold">Availability</h2>
                            <p>{currentItem.available ? 'Available' : 'Not Available'}</p>
                        </div>
                        <div>
                            <h2 className="text-lg font-bold">Preparation Time</h2>
                            <p>{currentItem.preparation_time ? `${currentItem.preparation_time} mins` : 'N/A'}</p>
                        </div>
                        <div>
                            <h2 className="text-lg font-bold">Max Available Quantity</h2>
                            <p>{currentItem.maxAvailable !== undefined ? currentItem.maxAvailable.toString() : 'N/A'}</p>
                        </div>
                    </div>
                ) : (
                    <p>No item details available.</p>
                )}
            </Modal>
        </div>
    );
};

export default MenuItems;