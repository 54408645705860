// client/src/components/IngredientDetailsModal.js

import React from 'react';
import Modal from './Modal';

const IngredientDetailsModal = ({ isOpen, onClose, ingredientDetails }) => {
    if (!isOpen) return null;

    return (
        <Modal isOpen={isOpen} onClose={onClose} title="Ingredient Details">
            {ingredientDetails ? (
                <div>
                    <p><strong>Name:</strong> {ingredientDetails.name.en}</p>
                    <p><strong>Description:</strong> {ingredientDetails.description.en}</p>
                    <p><strong>Classification:</strong> {ingredientDetails.classification}</p>
                    <p><strong>Nutrition Info:</strong> {ingredientDetails.nutrition_info.en}</p>
                    <p><strong>Allergens:</strong> {ingredientDetails.allergens.join(', ')}</p>
                    <p><strong>Perishable:</strong> {ingredientDetails.perishable ? 'Yes' : 'No'}</p>
                    <p><strong>Origin:</strong> {ingredientDetails.origin}</p>
                </div>
            ) : (
                <p>Loading ingredient details...</p>
            )}
        </Modal>
    );
};

export default IngredientDetailsModal;
