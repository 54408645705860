// client/src/context/authContext.js

import React, { createContext, useState, useEffect } from 'react';
import api from '../services/api';
import { decodeToken } from '../utils/decodeToken';

const AuthContext = createContext(); // Correctly create and export the context

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [adminExists, setAdminExists] = useState(true);

    useEffect(() => {
        const loadUser = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                const decodedToken = decodeToken(token);
                if (decodedToken && decodedToken.exp * 1000 > Date.now()) {
                    try {
                        const response = await api.get('/auth/me', {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        });
                        setUser(response.data);
                    } catch (err) {
                        localStorage.removeItem('token');
                        setUser(null);
                    }
                } else {
                    localStorage.removeItem('token');
                    setUser(null);
                }
            }

            const checkAdminExists = async () => {
                try {
                    const response = await api.get('/auth/admin-exists');
                    setAdminExists(response.data.exists); // Correctly set the adminExists state
                } catch (err) {
                    setAdminExists(false);
                }
            };

            await checkAdminExists();
            setLoading(false);
        };

        loadUser();
    }, []);


    const login = async (username, password) => {
        const response = await api.post('/auth/login', { username, password });
        localStorage.setItem('token', response.data.accessToken);
        setUser(response.data.user);
    };

    const logout = () => {
        localStorage.removeItem('token');
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, loading, login, logout, adminExists }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
