// client/src/components/MenuItemDetailsModal.js

import React from 'react';
import Modal from './Modal';

const MenuItemDetailsModal = ({ isOpen, onClose, menuItemDetails }) => {
    if (!isOpen) return null;

    return (
        <Modal isOpen={isOpen} onClose={onClose} title="Menu Item Details">
            {menuItemDetails ? (
                <div>
                    <p><strong>Name:</strong> {menuItemDetails.name}</p>
                    <p><strong>Description:</strong> {menuItemDetails.description}</p>
                    <p><strong>Price:</strong> {menuItemDetails.price}</p>
                    <p><strong>Preparation Time:</strong> {menuItemDetails.preparation_time} minutes</p>
                    <h3>Ingredients</h3>
                    {menuItemDetails.ingredients.map((ingredient, index) => (
                        <div key={index}>
                            <p><strong>Ingredient Name:</strong> {ingredient.ingredient_id.name.en}</p>
                            <p><strong>Quantity:</strong> {ingredient.quantity} {ingredient.unit.abbreviation}</p>
                        </div>
                    ))}
                </div>
            ) : (
                <p>Loading menu item details...</p>
            )}
        </Modal>
    );
};

export default MenuItemDetailsModal;
