import { useState } from 'react';

export const useFormData = (initialData) => {
    const [formData, setFormData] = useState({
        name: initialData?.name || '',
        description: initialData?.description || '',
        price: initialData?.price || '',
        category_id: initialData?.category_id || '',
        ingredients: initialData?.ingredients || [{ ingredient_id: '', quantity: '', unit: '' }],
        nutritionInfo: initialData?.nutritionInfo || {},
    });

    const handleChange = (name, value) => {
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    return { formData, handleChange, setFormData };
};
