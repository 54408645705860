// client/src/components/Suppliers.js

import React, { useState, useEffect } from 'react';
import api from '../services/api';
import { toast } from 'react-toastify';
import Table from './Table';
import Modal from './Modal';
import SupplierForm from './SupplierForm';
import Loader from './Loader';

const Suppliers = () => {
    const [suppliers, setSuppliers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentSupplier, setCurrentSupplier] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSuppliers = async () => {
            try {
                const response = await api.get('/suppliers');
                setSuppliers(response.data);
            } catch (error) {
                toast.error('Failed to load suppliers');
            } finally {
                setLoading(false);
            }
        };

        fetchSuppliers();
    }, []);

    const handleOpenModal = (supplier = null) => {
        setCurrentSupplier(supplier);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setCurrentSupplier(null);
    };

    const refreshData = async () => {
        try {
            const response = await api.get('/suppliers');
            setSuppliers(response.data);
        } catch (error) {
            toast.error('Failed to refresh data');
        }
    };

    const handleDelete = async (supplierId) => {
        if (window.confirm('Are you sure you want to delete this supplier?')) {
            try {
                await api.delete(`/suppliers/${supplierId}`);
                toast.success('Supplier deleted successfully.');
                refreshData();
            } catch (error) {
                toast.error('Failed to delete supplier.');
            }
        }
    };

    const renderStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(
                <svg
                    key={i}
                    className={`w-4 h-4 ${i <= rating ? 'text-yellow-400' : 'text-gray-300'}`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.285 3.958a1 1 0 00.95.69h4.222c.969 0 1.371 1.24.588 1.81l-3.416 2.488a1 1 0 00-.364 1.118l1.285 3.958c.3.921-.755 1.688-1.54 1.118l-3.416-2.488a1 1 0 00-1.175 0l-3.416 2.488c-.784.57-1.839-.197-1.54-1.118l1.285-3.958a1 1 0 00-.364-1.118L2.34 9.385c-.783-.57-.38-1.81.588-1.81h4.222a1 1 0 00.95-.69L9.049 2.927z" />
                </svg>
            );
        }
        return <div className="flex">{stars}</div>;
    };

    const renderBar = (value, max = 10) => (
        <div className="w-full bg-gray-200 rounded-full h-2.5">
            <div
                className="bg-blue-600 h-2.5 rounded-full"
                style={{ width: `${(value / max) * 100}%` }}
            ></div>
        </div>
    );

    if (loading) {
        return <Loader />;
    }

    return (
        <div>
            <button
                className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition duration-200 mb-4"
                onClick={() => handleOpenModal()}
            >
                Add Supplier
            </button>
            <Table
                columns={[
                    'Name',
                    'Contact Name',
                    'Phone',
                    'Email',
                    'Delivery Time',
                    'Quality Issues',
                    'Cost Rating',
                    'Actions'
                ]}
                data={suppliers.map(supplier => {
                    const {
                        delivery_time = 0,
                        quality_issues = 0,
                        cost_rating = 3
                    } = supplier.performance || {};

                    return {
                        name: supplier.name,
                        contact_name: supplier.contact_name,
                        phone: supplier.phone,
                        email: supplier.email,
                        delivery_time: renderBar(delivery_time, 30), // Assuming max delivery time of 30 days
                        quality_issues: renderBar(quality_issues, 10), // Assuming max 10 quality issues
                        cost_rating: renderStars(cost_rating),
                        actions: (
                            <div className="flex space-x-2">
                                <button
                                    className="bg-blue-600 text-white py-1 px-2 rounded hover:bg-blue-700 transition duration-200"
                                    onClick={() => handleOpenModal(supplier)}
                                >
                                    Edit
                                </button>
                                <button
                                    className="bg-red-600 text-white py-1 px-2 rounded hover:bg-red-700 transition duration-200"
                                    onClick={() => handleDelete(supplier._id)}
                                >
                                    Delete
                                </button>
                            </div>
                        ),
                    };
                })}
            />
            <Modal isOpen={isModalOpen} onClose={closeModal} title={currentSupplier ? 'Edit Supplier' : 'Add Supplier'}>
                <SupplierForm
                    supplier={currentSupplier}
                    onSave={() => {
                        closeModal();
                        refreshData();
                    }}
                    onCancel={closeModal}
                />
            </Modal>
        </div>
    );
};

export default Suppliers;

