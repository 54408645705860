// client/src/pages/ChefOrders.js

import React, { useState, useEffect, useContext } from 'react';
import api from '../services/api';
import Table from '../components/Table';
import { toast } from 'react-toastify';
import SettingsContext from '../context/SettingsContext';

const ChefOrders = () => {
    const [orders, setOrders] = useState([]);
    const [currentTime, setCurrentTime] = useState(Date.now());
    const [unavailableIngredients, setUnavailableIngredients] = useState(null);
    const [overrideOption, setOverrideOption] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const { overrideStockIssue } = useContext(SettingsContext);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                setIsLoading(true);
                setError(null);
                console.log('Fetching active orders...');
                const response = await api.get('/orders/active');
                console.log('Response received:', response);

                if (response.data && Array.isArray(response.data)) {
                    setOrders(response.data);
                    console.log(`Received ${response.data.length} orders`);
                } else {
                    console.warn('Unexpected response format:', response.data);
                    setOrders([]);
                }
            } catch (error) {
                console.error('Error fetching active orders:', error);
                let errorMessage = 'Failed to retrieve orders. Please try again later.';
                if (error.response) {
                    errorMessage = `Server error: ${error.response.data.error || error.response.statusText}`;
                } else if (error.request) {
                    errorMessage = 'No response received from server';
                } else {
                    errorMessage = error.message;
                }
                setError(errorMessage);
                toast.error(errorMessage);
            } finally {
                setIsLoading(false);
            }
        };

        // Set up order fetching every 30 seconds
        fetchOrders();
        const orderFetchInterval = setInterval(fetchOrders, 30000);

        // Set up time update every second
        const timeInterval = setInterval(() => {
            setCurrentTime(Date.now());
        }, 1000);

        return () => {
            clearInterval(orderFetchInterval);
            clearInterval(timeInterval); // Clear the timer when the component unmounts
        };
    }, []);

    const timeDifference = (startTime) => {
        if (!startTime) return "00:00:00";

        const diffInSeconds = Math.floor((currentTime - new Date(startTime).getTime()) / 1000);
        const hours = Math.floor(diffInSeconds / 3600);
        const minutes = Math.floor((diffInSeconds % 3600) / 60);
        const seconds = diffInSeconds % 60;

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const handleStatusChange = async (orderId, itemId, newStatus, override = false) => {
        try {
            await api.put(`/orders/${orderId}/items/${itemId}/status`, { status: newStatus, override });
            toast.success('Order item status updated successfully');
            setUnavailableIngredients(null);

            // Fetch updated orders after status change
            const response = await api.get('/orders/active');
            setOrders(response.data);
        } catch (error) {
            if (error.response?.data?.unavailableIngredients) {
                setUnavailableIngredients(error.response.data.unavailableIngredients);
                if (overrideStockIssue) {
                    setOverrideOption(true);
                }
            } else {
                toast.error('Failed to update order item status');
            }
        }
    };

    const handleOverride = (orderId, itemId) => {
        handleStatusChange(orderId, itemId, 'Cooking', true);
    };

    const columns = ['Table Number', 'Menu Item', 'Quantity', 'Status', 'Time Since Order Placed', 'Actions'];

    const tableData = orders.flatMap(order =>
        order.items.map(item => {
            // Find the latest timestamp for the current status
            const lastStatusTimestamp = item.status_timestamps
                ? item.status_timestamps[item.status_timestamps.length - 1]?.timestamp
                : order.createdAt; // Fallback to order created time if no status timestamp exists

            return {
                table_number: order.table_id.number,
                menu_item: item.name,
                quantity: item.quantity,
                status: (
                    <>
                        {item.preparation_phase}{' '}
                        <span className="text-sm text-gray-500">({timeDifference(lastStatusTimestamp)})</span>
                    </>
                ),
                time_since_order: timeDifference(order.createdAt),
                actions: (
                    <div className="flex space-x-2">
                        {item.preparation_phase === 'Queued' && (
                            <button
                                onClick={() => handleStatusChange(order._id, item._id, 'Cooking')}
                                className="bg-yellow-500 text-white p-1 rounded hover:bg-yellow-600"
                            >
                                Start Cooking
                            </button>
                        )}
                        {item.preparation_phase === 'Cooking' && (
                            <button
                                onClick={() => handleStatusChange(order._id, item._id, 'Ready for Pickup')}
                                className="bg-green-500 text-white p-1 rounded hover:bg-green-600"
                            >
                                Ready for Pickup
                            </button>
                        )}
                        {item.preparation_phase === 'Ready for Pickup' && (
                            <button
                                onClick={() => handleStatusChange(order._id, item._id, 'Served')}
                                className="bg-blue-500 text-white p-1 rounded hover:bg-blue-600"
                            >
                                Mark as Served
                            </button>
                        )}
                    </div>
                ),
            };
        })
    );


    return (
        <div className="container mx-auto mt-10">
            <h1 className="text-2xl font-bold mb-6">Chef Order Management</h1>

            {isLoading && <p className="text-gray-600">Loading orders...</p>}

            {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold">Error: </strong>
                    <span className="block sm:inline">{error}</span>
                    <p className="mt-2">
                        If this error persists, please contact the IT support team with the following information:
                        <br />
                        Time: {new Date().toLocaleString()}
                        <br />
                        Error: {error}
                        <br />
                        Please check the browser console for more detailed error information.
                    </p>
                </div>
            )}

            {!isLoading && !error && orders.length === 0 && (
                <p className="text-gray-600">No active orders at the moment. The kitchen is all caught up!</p>
            )}

            {!isLoading && !error && orders.length > 0 && (
                <Table columns={columns} data={tableData} />
            )}

            {unavailableIngredients && (
                <div className="bg-red-100 p-4 rounded-md mb-4">
                    <h2 className="text-red-700 font-bold mb-2">Not enough stock available for the following ingredients:</h2>
                    <ul className="list-disc list-inside text-red-700">
                        {unavailableIngredients.map((ingredient, index) => (
                            <li key={index}>{ingredient}</li>
                        ))}
                    </ul>
                    {overrideOption && (
                        <button
                            onClick={() => handleOverride('orderId', 'itemId')}
                            className="bg-red-500 text-white p-2 rounded hover:bg-red-600 mt-2"
                        >
                            Override and Continue
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default ChefOrders;

