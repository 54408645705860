// client/src/components/SettingsTab.js

import React, { useState, useEffect } from 'react';
import Loader from './Loader';

const SettingsTab = ({ tabs, selectedTab, onSelectTab }) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchTabContent = async () => {
            setLoading(true);
            try {
                // Simulate a delay to mimic fetching content (replace with real data fetching if needed)
                await new Promise((resolve) => setTimeout(resolve, 500));
            } catch (error) {
                // Handle the error, e.g., by showing a toast notification
            } finally {
                setLoading(false);
            }
        };

        fetchTabContent();
    }, [selectedTab]);

    return (
        <div>
            <div className="flex space-x-4 border-b border-gray-300 mb-6">
                {tabs.map((tab) => (
                    <button
                        key={tab.key}
                        onClick={() => onSelectTab(tab.key)}
                        className={`py-2 px-4 transition-colors duration-300 ${
                            selectedTab === tab.key
                                ? 'border-b-4 border-blue-600 text-blue-600 font-semibold'
                                : 'text-gray-600 hover:text-blue-600'
                        }`}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>
            <div>
                {loading ? (
                    <Loader />
                ) : (
                    tabs.find((tab) => tab.key === selectedTab)?.content
                )}
            </div>
        </div>
    );
};

export default SettingsTab;
