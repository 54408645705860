// client/src/components/IngredientForm.js

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Select from 'react-select';
import api from '../services/api';
import { toast } from 'react-toastify';

const IngredientForm = ({ ingredient = {}, onSave, onCancel }) => {
    const [formData, setFormData] = useState({
        name: ingredient?.name || { en: '' },
        description: ingredient?.description || { en: '' },
        classification: ingredient?.classification || '',
        nutrition_info: ingredient?.nutrition_info || { en: '' },
        allergens: ingredient?.allergens || [],
        tags: ingredient?.tags || [],
        perishable: ingredient?.perishable || false,
        shelf_life: ingredient?.shelf_life || '',
        origin: ingredient?.origin || 'Unknown',
        storage_condition: ingredient?.storage_condition || '',
    });

    const [options, setOptions] = useState({
        classifications: [],
        allergens: [],
    });

    useEffect(() => {
        setFormData(ingredient);
    }, [ingredient]);

    useEffect(() => {
        setOptions({
            classifications: [
                { value: 'Vegetarian', label: 'Vegetarian' },
                { value: 'Non-Vegetarian', label: 'Non-Vegetarian' },
                { value: 'Eggetarian', label: 'Eggetarian' },
                { value: 'Vegan', label: 'Vegan' },
            ],
            allergens: [
                { value: 'Peanuts', label: 'Peanuts' },
                { value: 'Gluten', label: 'Gluten' },
                { value: 'Soy', label: 'Soy' },
                { value: 'Dairy', label: 'Dairy' },
                { value: 'Tree Nuts', label: 'Tree Nuts' },
                { value: 'Eggs', label: 'Eggs' },
                { value: 'Fish', label: 'Fish' },
                { value: 'Shellfish', label: 'Shellfish' },
            ],
        });
    }, []);

    const handleChange = useCallback((name, value) => {
        setFormData(prev => ({ ...prev, [name]: value }));
    }, []);

    const handleSelectChange = useCallback((name, selectedOption) => {
        handleChange(name, selectedOption ? selectedOption.value : '');
    }, [handleChange]);

    const handleMultiSelectChange = useCallback((name, selectedOptions) => {
        handleChange(name, selectedOptions ? selectedOptions.map(opt => opt.value) : []);
    }, [handleChange]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (ingredient?._id) {
                await api.put(`/ingredients/${ingredient._id}`, formData);
                toast.success('Ingredient updated successfully');
            } else {
                await api.post('/ingredients', formData);
                toast.success('Ingredient added successfully');
            }
            onSave();
        } catch (error) {
            toast.error('Failed to save ingredient');
        }
    };

    const selectOptions = useMemo(() => ({
        classifications: options.classifications,
        allergens: options.allergens,
    }), [options]);

    const renderSelect = useCallback((name, label, options, isRequired = false, isMulti = false) => (
        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{label}</label>
            <Select
                name={name}
                value={options.filter(opt => formData[name]?.includes(opt.value))}
                onChange={selectedOption => isMulti ? handleMultiSelectChange(name, selectedOption) : handleSelectChange(name, selectedOption)}
                options={options}
                isMulti={isMulti}
                className="react-select-container"
                classNamePrefix="react-select"
                required={isRequired}
            />
        </div>
    ), [formData, handleSelectChange, handleMultiSelectChange]);

    const renderInput = useCallback((name, label, type = "text", isRequired = false) => (
        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{label}</label>
            <input
                type={type}
                name={name}
                value={formData[name]}
                onChange={(e) => handleChange(name, e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                required={isRequired}
            />
        </div>
    ), [formData, handleChange]);

    return (
        <form onSubmit={handleSubmit}>
            {renderInput('name.en', 'Ingredient Name (English)', 'text', true)}
            {renderInput('description.en', 'Description (English)', 'textarea', true)}
            {renderSelect('classification', 'Classification', selectOptions.classifications, true)}
            {renderInput('nutrition_info.en', 'Nutrition Info (English)', 'textarea')}
            {renderSelect('allergens', 'Allergens', selectOptions.allergens, false, true)}
            {renderInput('tags', 'Tags (comma separated)', 'text')}
            {renderInput('shelf_life', 'Shelf Life (Days)', 'number')}
            {renderInput('origin', 'Origin')}
            {renderInput('storage_condition', 'Storage Condition')}
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Perishable</label>
                <input
                    type="checkbox"
                    name="perishable"
                    checked={formData.perishable}
                    onChange={(e) => handleChange('perishable', e.target.checked)}
                    className="mt-1 mr-2 leading-tight"
                />
                <span className="text-sm text-gray-700">Yes</span>
            </div>
            <div className="flex justify-end">
                <button
                    type="button"
                    onClick={onCancel}
                    className="mr-2 bg-gray-500 text-white px-4 py-2 rounded"
                >
                    Cancel
                </button>
                <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded">
                    Save
                </button>
            </div>
        </form>
    );
};

export default IngredientForm;





