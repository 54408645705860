// client/src/components/OrderingTable.js

import React from 'react';
import { Image, Group, Text } from 'react-konva';
import useImage from 'use-image';
import Konva from 'konva';

const OrderingTable = ({
                           table,
                           onTableClick,
                           onChairClick,
                           isSelected,
                           selectedChairIndex,
                           onMouseEnter,
                           onMouseLeave,
                           isHovered,
                           orderStatus,
                       }) => {
    const [roundTableImage] = useImage('/images/roundtable.png');
    const [squareTableImage] = useImage('/images/squaretable.png');
    const [rectTableImage] = useImage('/images/rectangletable.png');
    const [chairImage] = useImage('/images/chair.png');

    if (!table) {
        return null;
    }

    const { type, x, y, capacity, number } = table;
    const baseTableSize = 80;
    const chairSize = 20;
    const chairOffset = 10;

    const tableSizeMultiplier = Math.ceil(capacity / 4);
    const tableSize = baseTableSize + tableSizeMultiplier * 20;

    const getTableShape = () => {
        const commonProps = {
            shadowBlur: 15,
            shadowColor: 'rgba(0, 0, 0, 0.6)',
            shadowOffsetX: 5,
            shadowOffsetY: 10,
            shadowOpacity: 0.7,
            opacity: isHovered ? 0.8 : 1,
            cornerRadius: 10,
        };

        let tableImage = squareTableImage;
        if (type === 'circle') {
            tableImage = roundTableImage;
        } else if (type === 'rectangle') {
            tableImage = rectTableImage;
        }

        const tableWidth = type === 'rectangle' ? tableSize + 40 : tableSize;
        return (
            <Image
                image={tableImage}
                width={tableWidth}
                height={tableSize}
                {...commonProps}
                onClick={(e) => {
                    e.cancelBubble = true; // Prevent event bubbling
                    onTableClick(table);
                }}
                zIndex={2} // Ensure the table is always above the chairs
            />
        );
    };

    const distributeChairs = (capacity, isSquare = false) => {
        if (isSquare) {
            const chairsPerSide = Math.floor(capacity / 4);
            const extraChairs = capacity % 4;
            return [
                chairsPerSide + (extraChairs > 0 ? 1 : 0),
                chairsPerSide + (extraChairs > 1 ? 1 : 0),
                chairsPerSide + (extraChairs > 2 ? 1 : 0),
                chairsPerSide
            ];
        } else {
            const longSideCapacity = Math.floor(capacity / 2) * 2;
            const shortSideCapacity = capacity - longSideCapacity;

            const top = Math.ceil(longSideCapacity / 2);
            const bottom = longSideCapacity - top;
            const left = Math.ceil(shortSideCapacity / 2);
            const right = shortSideCapacity - left;

            return [top, bottom, left, right];
        }
    };

    const getChairPositions = () => {
        const chairPositions = [];

        switch (type) {
            case 'circle':
                for (let i = 0; i < capacity; i++) {
                    const angle = (i / capacity) * 2 * Math.PI - Math.PI / 2;
                    chairPositions.push({
                        x: tableSize / 2 + Math.cos(angle) * (tableSize / 2 + chairOffset),
                        y: tableSize / 2 + Math.sin(angle) * (tableSize / 2 + chairOffset)
                    });
                }
                break;
            case 'square':
            case 'rectangle':
                const isSquare = type === 'square';
                const width = isSquare ? tableSize : tableSize + 40;
                const height = tableSize;
                const [top, bottom, left, right] = distributeChairs(capacity, isSquare);

                const distributeSide = (count, isHorizontal, isPositive) => {
                    const sideLength = isHorizontal ? width : height;
                    const step = sideLength / (count + 1);
                    return Array.from({ length: count }, (_, i) => {
                        const pos = step * (i + 1);
                        return isHorizontal
                            ? { x: pos, y: isPositive ? height + chairOffset : -chairOffset }
                            : { x: isPositive ? width + chairOffset : -chairOffset, y: pos };
                    });
                };

                chairPositions.push(
                    ...distributeSide(top, true, false),    // Top
                    ...distributeSide(bottom, true, true),  // Bottom
                    ...distributeSide(left, false, false),  // Left
                    ...distributeSide(right, false, true)   // Right
                );
                break;
        }
        return chairPositions;
    };

    const rotateChair = (chairX, chairY, tableX, tableY) => {
        const dx = tableX - chairX;
        const dy = tableY - chairY;
        return Math.atan2(dy, dx) * (180 / Math.PI) - 90;
    };

    return (
        <Group
            x={x}
            y={y}
            onMouseEnter={() => onMouseEnter(table._id)}
            onMouseLeave={() => onMouseLeave()}
        >
            {/* Render chairs first */}
            {getChairPositions().map((pos, index) => {
                const tableCenterX = tableSize / 2;
                const tableCenterY = tableSize / 2;
                const rotation = rotateChair(pos.x, pos.y, tableCenterX, tableCenterY);
                return (
                    <Group
                        key={index}
                        x={pos.x}
                        y={pos.y}
                        rotation={rotation}
                        onClick={(e) => {
                            e.cancelBubble = true; // Prevent event bubbling
                            onChairClick(table, index); // Specific chair click
                        }}
                        zIndex={1} // Ensure chairs are below the table
                    >
                        <Image
                            image={chairImage}
                            width={chairSize}
                            height={chairSize}
                            offsetX={chairSize / 2}
                            offsetY={chairSize / 2}
                            shadowColor="rgba(0,0,0,0.4)"
                            shadowBlur={5}
                            shadowOffsetX={3}
                            shadowOffsetY={7}
                            shadowOpacity={0.5}
                            filters={[Konva.Filters.Brighten]}
                            brightness={0.1}
                        />
                        <Text
                            text={`${index + 1}`}
                            fontSize={10}
                            fill="white"
                            align="center"
                            verticalAlign="middle"
                            width={chairSize}
                            height={chairSize}
                            offsetX={chairSize / 2}
                            offsetY={chairSize / 2}
                        />
                    </Group>
                );
            })}
            {/* Render the table above the chairs */}
            {getTableShape()}
            <Text
                text={`${number}`}
                fontSize={16}
                fontStyle="bold"
                fill="black"
                align="center"
                verticalAlign="middle"
                width={type === 'rectangle' ? tableSize + 40 : tableSize}
                height={tableSize}
                onClick={(e) => {
                    e.cancelBubble = true; // Prevent event bubbling
                    onTableClick(table);
                }}
                zIndex={3} // Ensure the table number is above the table itself
            />
            {/* Display Order Status */}
            {orderStatus && (
                <Text
                    text={`Status: ${orderStatus.status}`}
                    fontSize={12}
                    fontStyle="italic"
                    fill="red"
                    align="center"
                    verticalAlign="middle"
                    width={type === 'rectangle' ? tableSize + 40 : tableSize}
                    height={tableSize}
                    y={tableSize + 10}
                    onClick={(e) => {
                        e.cancelBubble = true; // Prevent event bubbling
                        onTableClick(table);
                    }}
                    zIndex={4} // Ensure the status text is above the table itself
                />
            )}
        </Group>
    );
};

export default OrderingTable;

