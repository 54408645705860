// client/src/components/Layout.js

import React, { useContext, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Layout as AntLayout, Menu, Skeleton, Avatar, Button } from 'antd';
import { UserOutlined, DashboardOutlined, TableOutlined, CoffeeOutlined, FileTextOutlined, SettingOutlined, LogoutOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons';
import AuthContext from '../context/authContext';
import SettingsContext from '../context/SettingsContext';
import DetailsPanelContext from '../context/DetailsPanelContext';
import DetailsPanel from './DetailsPanel';

const { Sider, Content, Footer } = AntLayout;

const Layout = ({ children }) => {
    const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
    const { user, logout } = useContext(AuthContext);
    const { appName, primaryColor, secondaryColor } = useContext(SettingsContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { isVisible, title, content, closeDetailsPanel } = useContext(DetailsPanelContext);

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    React.useEffect(() => {
        setTimeout(() => setLoading(false), 1000);
    }, []);

    const menuItems = [
        { label: 'Dashboard', icon: <DashboardOutlined />, path: '/dashboard' },
        { label: 'Tables', icon: <TableOutlined />, path: '/tables' },
        { label: 'Menu Items', icon: <CoffeeOutlined />, path: '/menu-items' },
        { label: 'Orders', icon: <FileTextOutlined />, path: '/orders' },
        { label: 'Chef Orders', icon: <FileTextOutlined />, path: '/chef-orders' },
        { label: 'Settings', icon: <SettingOutlined />, path: '/settings' },
    ];

    return (
        <AntLayout style={{ minHeight: '100vh', display: 'flex', flexDirection: 'row' }}>
            {/* Left Sidebar */}
            <Sider
                collapsible
                collapsed={isMenuCollapsed}
                onCollapse={setIsMenuCollapsed}
                theme="dark"
                style={{ backgroundColor: secondaryColor }}
                width={250}
            >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '16px' }}>
                    <Avatar src="/path/to/logo.png" shape="square" size={isMenuCollapsed ? 40 : 64} alt="App Logo" />
                    {!isMenuCollapsed && <div style={{ marginLeft: '8px', fontWeight: 'bold', color: 'white' }}>{appName}</div>}
                </div>

                {/* Profile Section */}
                <div style={{ position: 'absolute', bottom: 0, width: '100%', textAlign: 'center', padding: '16px 0' }}>
                    <Avatar size={isMenuCollapsed ? 32 : 48} icon={<UserOutlined />} alt="User Avatar" />
                    {!isMenuCollapsed && (
                        <div style={{ color: 'white', marginTop: '8px' }}>
                            <div>{user?.username || 'User'}</div>
                            <span style={{ fontSize: '12px', color: '#d3d3d3' }}>View Profile</span>
                        </div>
                    )}
                </div>

                {/* Navigation Menu */}
                <Menu theme="dark" mode="inline" defaultSelectedKeys={['/dashboard']} style={{ backgroundColor: secondaryColor }}>
                    {menuItems.map(item => (
                        <Menu.Item key={item.path} icon={item.icon}>
                            <NavLink to={item.path}>{item.label}</NavLink>
                        </Menu.Item>
                    ))}
                    {user && (
                        <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
                            Logout
                        </Menu.Item>
                    )}
                </Menu>
            </Sider>

            {/* Center Content Area */}
            <AntLayout style={{ flex: 1, marginRight: isVisible ? '416px' : '16px', marginLeft: '16px' }}>
                <Content style={{ padding: '24px', backgroundColor: primaryColor, minHeight: '280px' }}>
                    {loading ? (
                        <Skeleton active />
                    ) : (
                        React.Children.map(children, (child) => {
                            if (React.isValidElement(child)) {
                                return React.cloneElement(child);
                            }
                            return child;
                        })
                    )}
                </Content>
                <Footer style={{ textAlign: 'center', backgroundColor: secondaryColor, color: 'white' }}>
                    &copy; 2024 {appName}. All rights reserved.
                </Footer>
            </AntLayout>

            {/* Toggle Button for Details Panel */}
            <Button
                type="primary"
                shape="circle"
                icon={isVisible ? <RightOutlined /> : <LeftOutlined />}
                onClick={() => closeDetailsPanel()}
                style={{
                    position: 'fixed',
                    top: '50%',
                    right: isVisible ? '416px' : '16px',
                    transform: 'translateY(-50%)',
                    zIndex: 2,
                }}
            />

            {/* Right Details Panel */}
            {isVisible && (
                <DetailsPanel title={title} onClose={closeDetailsPanel}>
                    {content}
                </DetailsPanel>
            )}
        </AntLayout>
    );
};

export default Layout;
