// client/src/components/DevicePairing.js

import React, { useState } from 'react';
import DeviceRegistry from './DeviceRegistry';

const DevicePairing = ({ onPair }) => {
    const [deviceId, setDeviceId] = useState('');
    const [deviceType, setDeviceType] = useState('scale');
    const [domain, setDomain] = useState('');

    const handlePairDevice = () => {
        if (!deviceId || !domain) {
            alert('Please fill in both the Device ID and Domain.');
            return;
        }

        const newDevice = {
            deviceId,
            type: deviceType,
            domain,
        };

        DeviceRegistry.addDevice(newDevice);
        onPair(newDevice); // Notify the app that a device is paired
    };

    return (
        <div>
            <h3>Pair a New Device</h3>
            <input
                type="text"
                placeholder="Device ID"
                value={deviceId}
                onChange={(e) => setDeviceId(e.target.value)}
            />
            <input
                type="text"
                placeholder="Domain or IP"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
            />
            <select
                value={deviceType}
                onChange={(e) => setDeviceType(e.target.value)}
            >
                <option value="scale">Weighing Scale</option>
                <option value="sensor">Sensor</option>
                {/* Add other device types */}
            </select>
            <button onClick={handlePairDevice}>Pair Device</button>
        </div>
    );
};

export default DevicePairing;
