// client/src/components/Stock.js

import React, { useState, useEffect, useContext } from 'react';
import api from '../services/api';
import SettingsContext from '../context/SettingsContext';
import { toast } from 'react-toastify';
import Table from './Table';
import Loader from './Loader';
import Modal from './Modal';
import StockForm from './StockForm';
import OrderDetailsModal from './OrderDetailsModal';
import MenuItemDetailsModal from './MenuItemDetailsModal';
import IngredientDetailsModal from './IngredientDetailsModal';
import { format, isBefore } from 'date-fns';

const Stock = () => {
    const [stockItems, setStockItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
    const [isMenuItemModalOpen, setIsMenuItemModalOpen] = useState(false);
    const [isIngredientModalOpen, setIsIngredientModalOpen] = useState(false);
    const [editingStock, setEditingStock] = useState(null);
    const [viewingStock, setViewingStock] = useState(null);
    const [stockUsageLogs, setStockUsageLogs] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [selectedMenuItem, setSelectedMenuItem] = useState(null);
    const [selectedIngredient, setSelectedIngredient] = useState(null);
    const { currencySymbol } = useContext(SettingsContext);

    useEffect(() => {
        fetchStock();
    }, []);

    const fetchStock = async () => {
        setLoading(true);
        try {
            const response = await api.get('/stocks');
            setStockItems(response.data);
        } catch (error) {
            console.error('Error fetching stock:', error);
            toast.error('Failed to load stock data');
        } finally {
            setLoading(false);
        }
    };

    const fetchStockUsageLogs = async (stockId) => {
        try {
            const response = await api.get(`/stock-usages/${stockId}`);
            setStockUsageLogs(response.data);
        } catch (error) {
            console.error('Error fetching stock usage logs:', error);
            toast.error('Failed to load stock usage logs');
        }
    };

    const handleOpenEditModal = (stock = null) => {
        setEditingStock(stock);
        setIsEditModalOpen(true);
    };

    const handleOpenViewModal = (stock) => {
        setViewingStock(stock);
        setIsViewModalOpen(true);
        fetchStockUsageLogs(stock.id);
    };

    const closeEditModal = () => {
        setIsEditModalOpen(false);
        setEditingStock(null);
    };

    const closeViewModal = () => {
        setIsViewModalOpen(false);
        setViewingStock(null);
        setStockUsageLogs([]);
    };

    const handleOpenOrderModal = (orderId) => {
        api.get(`/orders/${orderId}`).then((response) => {
            setSelectedOrder(response.data);
            setIsOrderModalOpen(true);
        }).catch((error) => {
            console.error('Error fetching order details:', error);
        });
    };

    const handleOpenMenuItemModal = (menuItemId) => {
        api.get(`/menu-items/${menuItemId}`).then((response) => {
            setSelectedMenuItem(response.data);
            setIsMenuItemModalOpen(true);
        }).catch((error) => {
            console.error('Error fetching menu item details:', error);
        });
    };

    const handleOpenIngredientModal = (ingredientId) => {
        api.get(`/ingredients/${ingredientId}`).then((response) => {
            setSelectedIngredient(response.data);
            setIsIngredientModalOpen(true);
        }).catch((error) => {
            console.error('Error fetching ingredient details:', error);
        });
    };

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this stock item?')) {
            try {
                await api.delete(`/stocks/${id}`);
                toast.success('Stock item deleted successfully');
                fetchStock();
            } catch (error) {
                console.error('Error deleting stock:', error);
                toast.error('Failed to delete stock item');
            }
        }
    };

    const calculateTotalPrice = (quantity, pricePerUnit) => {
        return (quantity * pricePerUnit).toFixed(2);
    };

    const getExpiryStatus = (expiryDate) => {
        const today = new Date();
        if (!expiryDate) return 'No Expiry';
        const expiry = new Date(expiryDate);
        return isBefore(expiry, today) ? 'Expired' : 'Valid';
    };

    if (loading) {
        return <Loader />;
    }

    const tableData = stockItems.map(stock => ({
        id: stock._id,  // Stock ID for reference
        ingredient: stock.ingredient_id?.name?.en || stock.ingredient_id?.name || 'N/A',
        quantity: stock.quantity_in_stock || 'N/A',
        unit: stock.unit?.abbreviation || stock.unit?.name || 'N/A',
        pricePerUnit: `${currencySymbol}${stock.price_per_unit.toFixed(2)}`,
        totalPrice: `${currencySymbol}${calculateTotalPrice(stock.quantity_in_stock, stock.price_per_unit)}`,
        expiryDate: stock.expiry_date ? format(new Date(stock.expiry_date), 'yyyy-MM-dd') : 'N/A',
        receivedDate: stock.received_date ? format(new Date(stock.received_date), 'yyyy-MM-dd') : 'N/A',
        expiryStatus: getExpiryStatus(stock.expiry_date),
        actions: (
            <div className="flex space-x-2">
                <button
                    onClick={() => handleOpenEditModal(stock)}
                    className="bg-yellow-500 text-white p-1 rounded hover:bg-yellow-600"
                >
                    Edit
                </button>
                <button
                    onClick={() => handleDelete(stock._id)}
                    className="bg-red-500 text-white p-1 rounded hover:bg-red-600"
                >
                    Delete
                </button>
            </div>
        ),
    }));

    return (
        <div>
            <h2 className="text-xl font-semibold mb-4">Stock Management</h2>
            <div className="flex space-x-4 mb-4">
                <button
                    className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200"
                    onClick={() => handleOpenEditModal()}
                >
                    Add Stock
                </button>
            </div>
            {stockItems.length > 0 ? (
                <Table
                    columns={['Ingredient', 'Quantity', 'Unit', 'Price per Unit', 'Total Price', 'Expiry Date', 'Received Date', 'Expiry Status', 'Actions']}
                    data={tableData}
                    rowClickable={true}
                    onRowClick={(row) => handleOpenViewModal(row)}
                />
            ) : (
                <p>No stock available. Please start by adding some stocks.</p>
            )}

            {/* Modal for Editing */}
            <Modal isOpen={isEditModalOpen} onClose={closeEditModal} title={editingStock ? "Edit Stock" : "Add Stock"}>
                <StockForm onSave={fetchStock} onCancel={closeEditModal} initialStock={editingStock} />
            </Modal>

            {/* Modal for Viewing */}
            <Modal isOpen={isViewModalOpen} onClose={closeViewModal} title="View Stock Details">
                {viewingStock ? (
                    <div>
                        <h3>Stock Information</h3>
                        <p><strong>Ingredient:</strong> {viewingStock.ingredient}</p>
                        <p><strong>Quantity:</strong> {viewingStock.quantity}</p>
                        <p><strong>Unit:</strong> {viewingStock.unit}</p>
                        <p><strong>Price Per Unit:</strong> {viewingStock.pricePerUnit}</p>
                        <p><strong>Total Price:</strong> {viewingStock.totalPrice}</p>
                        <p><strong>Expiry Date:</strong> {viewingStock.expiryDate}</p>
                        <p><strong>Received Date:</strong> {viewingStock.receivedDate}</p>
                        <p><strong>Expiry Status:</strong> {viewingStock.expiryStatus}</p>

                        <h3 className="mt-4">Stock Usage Logs</h3>
                        {stockUsageLogs.length > 0 ? (
                            <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-sm">
                                <thead>
                                <tr>
                                    <th className="px-4 py-2 border-b">Order ID</th>
                                    <th className="px-4 py-2 border-b">Menu Item</th>
                                    <th className="px-4 py-2 border-b">Ingredient</th>
                                    <th className="px-4 py-2 border-b">Used By</th>
                                </tr>
                                </thead>
                                <tbody>
                                {stockUsageLogs.map((log, index) => (
                                    <tr key={index}>
                                        <td className="px-4 py-2 border-b">
                                            <button onClick={() => handleOpenOrderModal(log.order_id)}>
                                                {log.order_name || 'N/A'}
                                            </button>
                                        </td>
                                        <td className="px-4 py-2 border-b">
                                            <button onClick={() => handleOpenMenuItemModal(log.menu_item_id)}>
                                                {log.menu_item_name}
                                            </button>
                                        </td>
                                        <td className="px-4 py-2 border-b">
                                            <button onClick={() => handleOpenIngredientModal(log.ingredient_id)}>
                                                {log.ingredient_name.en}
                                            </button>
                                        </td>
                                        <td className="px-4 py-2 border-b">
                                            <button onClick={() => handleOpenIngredientModal(log.used_by_id)}>
                                                {log.used_by_name}
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        ) : (
                            <p>No usage logs available for this stock.</p>
                        )}
                    </div>
                ) : (
                    <p>Loading stock details...</p>
                )}
            </Modal>

            {/* Order Details Modal */}
            <OrderDetailsModal
                isOpen={isOrderModalOpen}
                onClose={() => setIsOrderModalOpen(false)}
                orderDetails={selectedOrder}
            />

            {/* Menu Item Details Modal */}
            <MenuItemDetailsModal
                isOpen={isMenuItemModalOpen}
                onClose={() => setIsMenuItemModalOpen(false)}
                menuItemDetails={selectedMenuItem}
            />

            {/* Ingredient Details Modal */}
            <IngredientDetailsModal
                isOpen={isIngredientModalOpen}
                onClose={() => setIsIngredientModalOpen(false)}
                ingredientDetails={selectedIngredient}
            />
        </div>
    );
};

export default Stock;
