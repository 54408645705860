// client/src/context/DetailsPanelContext.js

import React, { createContext, useState } from 'react';

const DetailsPanelContext = createContext();

export const DetailsPanelProvider = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [title, setTitle] = useState('Details');
    const [content, setContent] = useState(<p>Select an item to view details.</p>);

    const updateDetailsPanel = (newTitle, newContent) => {
        setTitle(newTitle);
        setContent(newContent);
        setIsVisible(true);
    };

    const closeDetailsPanel = () => setIsVisible(false);

    return (
        <DetailsPanelContext.Provider value={{ isVisible, title, content, updateDetailsPanel, closeDetailsPanel }}>
            {children}
        </DetailsPanelContext.Provider>
    );
};

export default DetailsPanelContext;
