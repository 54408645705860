// client/src/components/UserManagement.js
import React, { useState, useEffect } from 'react';
import api from '../services/api';
import { toast } from 'react-toastify';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [roleName, setRoleName] = useState('');
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        fetchUsers();
        fetchRoles();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await api.get('/users');
            setUsers(response.data);
        } catch (error) {
            toast.error('Failed to load users');
        }
    };

    const fetchRoles = async () => {
        try {
            const response = await api.get('/roles');
            setRoles(response.data);
        } catch (error) {
            toast.error('Failed to load roles');
        }
    };

    const handleAddUser = async () => {
        try {
            await api.post('/users', { name, username, email, password, roleName });
            toast.success('User added successfully');
            fetchUsers();
            // Reset form fields
            setName('');
            setUsername('');
            setEmail('');
            setPassword('');
            setRoleName('');
        } catch (error) {
            toast.error('Failed to add user');
        }
    };

    return (
        <div>
            <h2 className="text-xl font-bold mb-4">User Management</h2>
            <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="input"
            />
            <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="input"
            />
            <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="input"
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="input"
            />
            <select
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
                className="input"
            >
                <option value="">Select Role</option>
                {roles.map((role) => (
                    <option key={role._id} value={role.name}>
                        {role.name}
                    </option>
                ))}
            </select>
            <button onClick={handleAddUser} className="btn btn-primary mt-4">
                Add User
            </button>

            <h3 className="mt-6">Existing Users:</h3>
            <ul>
                {users.map((user) => (
                    <li key={user._id}>{user.username} - {user.role.name}</li>
                ))}
            </ul>
        </div>
    );
};

export default UserManagement;