// client/src/components/ManagementSettings.js

import React, { useState } from 'react';
import Suppliers from './Suppliers';
import StorageLocations from './StorageLocations';

const ManagementSettings = () => {
    const [selectedTab, setSelectedTab] = useState('suppliers');

    const renderTabContent = () => {
        switch (selectedTab) {
            case 'suppliers':
                return <Suppliers />;
            case 'storageLocations':
                return <StorageLocations />;
            default:
                return <Suppliers />;
        }
    };

    return (
        <div className="management-settings-container">
            <div className="tabs mb-4">
                <button
                    className={`tab ${selectedTab === 'suppliers' ? 'active' : ''}`}
                    onClick={() => setSelectedTab('suppliers')}
                >
                    Suppliers
                </button>
                <button
                    className={`tab ${selectedTab === 'storageLocations' ? 'active' : ''}`}
                    onClick={() => setSelectedTab('storageLocations')}
                >
                    Storage Locations
                </button>
            </div>
            <div className="tab-content">
                {renderTabContent()}
            </div>
        </div>
    );
};

export default ManagementSettings;
