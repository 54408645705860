// client/src/components/MenuItemForm.js

import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import api from '../services/api';
import FormInput from './FormInput';
import FormSelect from './FormSelect';
import IngredientsList from './IngredientsList';
import NutritionInfo from './NutritionInfo';
import { useFormData } from './useFormData';
import { useFetchData } from './useFetchData';

const MenuItemForm = ({ onClose, refreshData, initialData = {} }) => {
    const { formData, handleChange, setFormData } = useFormData({
        ...initialData,
        category_id: initialData.category_id?._id || initialData.category_id
    });
    const { categories, units, allIngredients, isLoading, loadError } = useFetchData();
    const [errors, setErrors] = useState({});

    const isEditing = !!initialData._id;

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name?.trim()) newErrors.name = 'Menu item name is required';
        if (!formData.description?.trim()) newErrors.description = 'Description is required';
        if (!formData.price || formData.price <= 0) newErrors.price = 'Price must be greater than zero';
        if (!formData.category_id) newErrors.category_id = 'Category is required';
        if (formData.ingredients?.some(ing => !ing.ingredient_id || !ing.quantity || !ing.unit)) {
            newErrors.ingredients = 'All ingredients must have a valid ingredient, quantity, and unit';
        }
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }

        try {
            if (isEditing) {
                await api.put(`/menu-items/${initialData._id}`, formData);
                toast.success('Menu item updated successfully');
            } else {
                await api.post('/menu-items', formData);
                toast.success('Menu item added successfully');
            }
            refreshData();
            onClose();
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error(isEditing ? 'Failed to update menu item' : 'Failed to add menu item');
        }
    };

    useEffect(() => {
        if (isEditing && initialData.ingredients) {
            // Map initial ingredients to the correct format
            const mappedIngredients = initialData.ingredients.map(ing => ({
                ingredient_id: ing.ingredient_id._id,
                quantity: ing.quantity,
                unit: ing.unit
            }));
            setFormData(prev => ({ ...prev, ingredients: mappedIngredients }));
        }
    }, [isEditing, initialData, setFormData]);

    if (isLoading) return <div>Loading...</div>;
    if (loadError) return <div>Error: {loadError}</div>;

    return (
        <form onSubmit={handleSubmit}>
            <FormInput
                name="name"
                label="Menu Item Name"
                value={formData.name || ''}
                onChange={handleChange}
                error={errors.name}
                required
            />
            <FormInput
                name="description"
                label="Description"
                value={formData.description || ''}
                onChange={handleChange}
                error={errors.description}
                required
                textarea
            />
            <FormInput
                name="price"
                label="Price"
                value={formData.price || ''}
                onChange={handleChange}
                error={errors.price}
                required
                type="number"
            />
            <FormSelect
                name="category_id"
                label="Category"
                value={formData.category_id || ''}
                onChange={handleChange}
                options={categories}
                error={errors.category_id}
                required
            />
            <IngredientsList
                ingredients={formData.ingredients || []}
                allIngredients={allIngredients}
                units={units}
                onChange={(ingredients) => handleChange('ingredients', ingredients)}
                error={errors.ingredients}
            />
            <NutritionInfo
                nutritionInfo={formData.nutritionInfo || {}}
                onChange={(nutritionInfo) => handleChange('nutritionInfo', nutritionInfo)}
            />
            <button
                type="submit"
                className="bg-yellow-600 text-white py-2 px-4 rounded hover:bg-yellow-700 transition duration-200"
            >
                {isEditing ? 'Update' : 'Save'}
            </button>
        </form>
    );
};

export default MenuItemForm;

