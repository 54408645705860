// client/src/components/OrderDetailsModal.js

import React from 'react';
import Modal from './Modal';

const OrderDetailsModal = ({ isOpen, onClose, orderDetails }) => {
    if (!isOpen) return null;

    return (
        <Modal isOpen={isOpen} onClose={onClose} title="Order Details">
            {orderDetails ? (
                <div>
                    <p><strong>Order ID:</strong> {orderDetails.order_id}</p>
                    <p><strong>Status:</strong> {orderDetails.status}</p>
                    <p><strong>Total Amount:</strong> {orderDetails.total_amount}</p>
                    <p><strong>Created At:</strong> {new Date(orderDetails.createdAt).toLocaleString()}</p>
                    <p><strong>Updated At:</strong> {new Date(orderDetails.updatedAt).toLocaleString()}</p>
                    <h3>Table Information</h3>
                    <p><strong>Table Number:</strong> {orderDetails.table_id.number}</p>
                    <p><strong>Capacity:</strong> {orderDetails.table_id.capacity}</p>
                    <p><strong>Status:</strong> {orderDetails.table_id.status}</p>
                    <h3>Items</h3>
                    {orderDetails.items.map((item, index) => (
                        <div key={index}>
                            <p><strong>Item Name:</strong> {item.menu_item_id.name}</p>
                            <p><strong>Quantity:</strong> {item.quantity}</p>
                            <p><strong>Price:</strong> {item.price}</p>
                            <p><strong>Preparation Status:</strong> {item.preparation_phase}</p>
                        </div>
                    ))}
                </div>
            ) : (
                <p>Loading order details...</p>
            )}
        </Modal>
    );
};

export default OrderDetailsModal;
