// client/src/components/StarRating.js

import React from 'react';

const StarRating = ({ rating, onRatingChange }) => {
    const handleClick = (newRating) => {
        onRatingChange(newRating);
    };

    return (
        <div>
            {[1, 2, 3, 4, 5].map((star) => (
                <span
                    key={star}
                    onClick={() => handleClick(star)}
                    style={{ cursor: 'pointer', color: star <= rating ? '#ffc107' : '#e4e5e9' }}
                >
                    ★
                </span>
            ))}
        </div>
    );
};

export default StarRating;
